<template>
  <div
    class="overflow-y-auto pb-20 md:pb-24"
    id="clinicList"
    :style="sliderHeight"
  >
    <div v-if="clinicLists.length > 0">
      <div class="flex flex-col items-start">
        <div v-if="clinicLists.length == 0" >
          <SkeletonClinic v-for="(clinicIndex, index) in mapListsToShow" :key="index"></SkeletonClinic>
        </div>
        <div v-for="(clinicIndex, index) in mapListsToShow" :key="index" v-else  class="w-full">
          <ClinicLocationList
            v-if="clinicLists[index]"
            :clinicList="clinicLists[index]"
    
          ></ClinicLocationList>
        </div>
      </div>
      <div
        v-if="mapListsToShow < clinicLists.length"
        class="flex justify-center my-8 md:my-16 relative"
      >
        <button
          class="rounded text-blog-18 text-secondary py-2 px-4 border border-secondary"
          @click.prevent="onClickNextData"
        >
          {{ $t("clinic.btnShowMoreClinics") }}
        </button>
      </div>
    </div>
    <div
      v-else-if="clinicLists.data == 'empty'"
      class="p-8 flex justify-center font-bold text-secondary"
    >
      {{ $t("clinic.btnShowMoreClinics", {range: globalStore.siteSearchRange}) }}
    </div>
  </div>
</template>

<script setup>
import { useGlobalStore } from "@/stores/global";

const globalStore = useGlobalStore();
const props = defineProps({
  isMobile: {
    type: Boolean,
  },
  clinicLists: {
    type: [Array, Object],
  },
  showGoogleMap: {
    type: Boolean,
    defualt: false,
  },
  showClose: {
    type: Boolean,
  },
  sliderHeight: {
    type: String,
    default: "height: calc(100vh - 250px); margin-top:16rem;",
  },
});
const pageLimit = ref(10);
const mapListsToShow = ref();

const emit = defineEmits([
  "selectedClinicTitle",
  "searchText",
  "clearSearch",
  "onTabClick",
]);

const showClose = ref(false);
const showModal = ref(false);

onMounted(() => {
  mapListsToShow.value = pageLimit.value;
});
onUnmounted(() => {
  mapListsToShow.value = 10;
});

function onClickNextData(e) {
  e.preventDefault();
  // animatedClass.value = 'pagination-underline'
  if (mapListsToShow.value > props.clinicLists.length) return;
  mapListsToShow.value += pageLimit.value;
}
</script>
<style scoped>
@media screen and (min-device-width: 736px) {
  /* width */
  ::-webkit-scrollbar {
    width: 0px;
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 2s ease-in-out;
  transition-timing-function: ease-in-out;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
}
</style>
